import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {
    deleteLogbookEntry, getBonusEntriesStatus,
    getLogbookEntry,
    getLogbookEntryCategories,
    patchLogbookEntry,
    postLogbookEntry
} from '../../../api/logbook'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Input, MultiSelect, RadioGroup, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfoAllWithImages, getStudentInfoGrouped, getStudentInfoGroupedCourse} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Alert from "../../elements/Alert";
import StudentSelector from "./StudentSelector";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import env from "react-dotenv";

export default function LogbookEntryCreateEdit() {

    const queryClient = useQueryClient()
    const {currentUser, permissions} = useAuth()

    const visibilityOptions = [
        {
            value: 'ALL',
            name: 'Eltern, Schüler, Team'
        },
        {
            value: 'STAFF',
            name: 'Team'
        },
        {
            value: 'MANAGEMENT',
            name: 'nur Leitung, Verfasser und Mentoren des Schülers'
        },
    ]

    const {id, studentId} = useParams()
    const [data, setData] = useState({
        id: null,
        student_id: studentId ? [parseInt(studentId)] : [],
        title: "",
        entry: "",
        categories: [],
        visibility: "ALL",
        number_of_penalties: 0,
        plupu_points: 0,
    })

    const {data: students} = useQuery(["student_info_with_images"], getStudentInfoAllWithImages, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: categories} = useQuery(['logbook_entry_categories'], getLogbookEntryCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: bonusEntriesStatus} = useQuery(['bonus_entries_status', currentUser.id], getBonusEntriesStatus, {refetchInterval: 1000 * 60 * 60})

    const [studentSelectorOpen, setStudentSelectorOpen] = useState(false)
    const {data: groups} = useQuery(["student_info_grouped"], getStudentInfoGrouped, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: coursesGrouped} = useQuery(["student_info_grouped_course"], getStudentInfoGroupedCourse, {refetchInterval: 1000 * 60 * 60 * 24})

    const canGivePluPuPoints = checkPermission(permissions, "student_logbook.give_plupu_points") && env.SCHOOL === 'KPI'

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getLogbookEntry(id)
        setData(newData)
    }

    const schema = {
        student_id: Joi.any().required(),
        title: Joi.string(),
        entry: Joi.string().required(),
    }

    const title = (id || data.id) ? "Logbucheintrag bearbeiten" : "Neuen Logbucheintrag erstellen"
    const successMessage = (id || data.id) ? "Logbucheintrag aktualisiert" : "Neuen Logbucheintrag erstellt"

    async function onSave() {
        // validation happens in Form component
        if (!data.id) await postLogbookEntry(data)
        else await patchLogbookEntry(data)
        queryClient.invalidateQueries({queryKey: ['logbook_entries', 'logbook_student_detail']})
        toast.success(successMessage)
        navigate(-1)
    }

    async function onDelete() {
        await deleteLogbookEntry(data.id)
        toast.success("Logbucheintrag gelöscht.")
        navigate(`/logbook`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleMultiSelectChange(value, fieldKey) {
        setData({
            ...data,
            [fieldKey]: value,
        });
    }

    const lobCategoriesSelected = data.categories?.findIndex(c => c.name.includes('Lob')) >= 0 && env.SCHOOL === 'KPI'

    return (

        <>
            {!id && <StudentSelector
                open={studentSelectorOpen}
                setOpen={setStudentSelectorOpen}
                allStudents={students}
                setData={setData}
                data={data}
                path="student_id"
                modalTitle="SchülerInnen auswählen"
                filters={{
                    ...groups,
                    ...coursesGrouped,
                }}
                parentLabelAttr={{
                    courses: "course_number",
                    houses: "name",
                    school_classes: "group",
                    grades: "name",
                }}
            />}

            <NarrowPage title={title}>

                <Form id="createLogbookEntry" onSave={onSave}
                      onDelete={onDelete}
                      data={data} setData={setData}
                      errors={errors} setErrors={setErrors}
                      successMessage={successMessage}
                      schema={schema}>

                    <FormSectionContent>

                        {id && <p className="sm:col-span-6 font-medium">{data.student?.full_name}</p>}
                        {!id && <MultiSelect
                            path="student_id"
                            label="SchülerIn"
                            className="sm:col-span-6"
                            options={students}
                            valueAttr="id"
                            labelAttr="full_name"
                            onChange={(value) =>
                                handleMultiSelectChange(value, "student_id")
                            }
                            errors={errors}
                            data={data}
                            isClearable={false}
                            valueOnly={true}
                        />}

                        {!id && !studentId && <div className="sm:col-span-6 flex">
                            <button
                                type="button"
                                onClick={() => setStudentSelectorOpen(true)}
                                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 mr-3"
                            >
                                SchülerInnen auswählen
                            </button>
                            {data.student_id.length ? (
                                <span
                                    className="bg-imsblue-100 text-imsblue-700 px-3 py-2 font-medium text-sm rounded-md"
                                >
                      <span className="inline-flex justify-center items-center
                      mr-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        {data.student_id.length}
                      </span>
                      SchülerInnen ausgewählt
                    </span>
                            ) : (
                                ""
                            )}
                        </div>}

                        <Input
                            path="title"
                            type="text"
                            label="Titel"
                            className="sm:col-span-6"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />

                        <TextArea
                            path="entry"
                            label="Eintrag"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            rows={10}
                            className="sm:col-span-6"
                            helpText=""
                        />

                        <div className={!canGivePluPuPoints && env.SCHOOL === 'KPI' ? "sm:col-span-6" : "sm:col-span-5"}>
                            <MultiSelect
                                path="categories"
                                label="Kategorien"
                                className="sm:col-span-6"
                                options={categories}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(value) => handleMultiSelectChange(value, "categories")}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            {env.SCHOOL === "KPI" && lobCategoriesSelected && bonusEntriesStatus?.can_give_entries &&
                            <Alert variant="info" extraClassName="mt-2 sm:col-span-6">
                                Sie können in dieser Epoche noch <strong>{bonusEntriesStatus?.bonus_entries_left_text}</strong> bepunktete Lobeinträge vergeben.
                            </Alert>}

                            {env.SCHOOL === "KPI" && lobCategoriesSelected && !bonusEntriesStatus?.can_give_entries &&
                                <Alert variant="warning" extraClassName="mt-2 sm:col-span-6">
                                    Sie können in dieser Epoche keine bepunkteten Lobeinträge mehr vergeben.
                                </Alert>}
                        </div>

                        {env.SCHOOL !== 'KPI' && <Input
                            path="number_of_penalties"
                            type="number"
                            label="Strafeinträge"
                            className="sm:col-span-1"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />}

                        {canGivePluPuPoints && <Input
                            path="plupu_points"
                            type="number"
                            label="PluPus"
                            className="sm:col-span-1"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />}

                        {data && <RadioGroup
                            path="visibility"
                            label="Sichtbar für"
                            className="sm:col-span-3"
                            options={visibilityOptions}
                            valueAttr="value"
                            labelAttr="name"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />}

                        {(data.visibility === 'STAFF' || data.visibility === 'MANAGEMENT') &&
                            <Alert variant="warning" extraClassName="sm:col-span-6">
                                Sie speichern diesen Eintrag als "nicht öffentlich". Bitte beachten Sie, dass Einträge
                                stets für Eltern und Schüler sichtbar sein sollten.<br/>
                                Ausnahmen gelten nur, wenn der Eintrag sich auf Umstände unseres Verantwortungbereichs
                                bezieht (z. B. nicht gemachte Hausaufgaben) oder Sie eine wichtige Information teilen
                                möchten, die der Schüler und die Eltern nicht erhalten sollten.<br/>
                                Bitte beachten Sie, dass Sie auch zwei Einträge mit unterschiedlichem Status machen
                                können.
                            </Alert>}

                    </FormSectionContent>

                </Form>

                <span onClick={() => navigate(-1)}
                      className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

            </NarrowPage>
        </>
    )
}
