import React, {useEffect, useState} from "react";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    DocumentArrowDownIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    PencilIcon,
    TrashIcon,
    XMarkIcon,
} from "@heroicons/react/20/solid";

import {
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {LazyLoadImage} from "react-lazy-load-image-component";
import {Link, useNavigate} from "react-router-dom";
import {formatCurrency, formatDate, formatDateTime, formatDateWithWeekday} from "../../../utils/formatters";
import fileDownload from "js-file-download";
import parse from "html-react-parser";
import {validateData} from "../../../utils/validateDataViewTable";
import {useAuth} from "../../../contexts/AuthContext";
import DatePicker from "react-datepicker";
import {useUserInfo} from "../../../contexts/UserInfoContext";

const getRowId = (row, relativeIndex, parent) => {
    return parent ? [parent.id, row.id].join(".") : row.id;
};

const includesString = (row, columnId, filterValue) => {
    const search = filterValue?.toLowerCase();
    // Convert to String
    const value = String(row.getValue(columnId));
    return value?.toLowerCase().includes(search);
};

export default function ListViewTable({
                                    data,
                                    columns,
                                    isLoading,
                                    getRowLink,
                                    getTrClassNames,
                                    disableGlobalSearch,
                                    rowSelection,
                                    setRowSelection,
                                    onDelete,
                                    searchQuery=null,
                                    setSearchQuery=null,
                                    filterMode,
                                    filters=null,
                                    setFilters=null,
                                    classes=null,
                                    houses=null,
                                    categories=null,
                                    targetGroups=null,
                                    securityList=null,
                                    hideHeader = false,
                                    noTopSpace = false
                                      }) {
    const [columnFiltersState, setColumnFiltersState] = useState([])
    const [columnFilters, setColumnFilters] = !filters
        ? [columnFiltersState, setColumnFiltersState]
        : [filters, setFilters];
    const [globalFilterState, setGlobalFilterState] = useState("")
    const [globalFilter, setGlobalFilter] = !setSearchQuery
        ? [globalFilterState, setGlobalFilterState]
        : [searchQuery, setSearchQuery];

    // const [globalSearchFilter, setGlobalSearchFilter] = useState({});

    const [dataTable, setDataTable] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setDataTable(validateData(data, columns))
    }, [data])

    const table = useReactTable({
        data: dataTable,
        columns,
        getRowId,
        state: {
            columnFilters: columnFiltersState,
            // columnFilters: columnFilters
            //          ? columnFiltersState
            //          : [],
            globalFilter: !setSearchQuery
                     ? globalFilter
                     : "",
            rowSelection,
        },
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: includesString,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        sortingFns: {
            lastNameSorting: (rowA, rowB, columnId) =>
                rowA.getValue(columnId).last_name < rowB.getValue(columnId).last_name
                    ? 1
                    : -1,
            schoolClassSorting: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId);
                const valueB = rowB.getValue(columnId);

                const getSortValue = (classValue) => {
                    if (classValue.startsWith("10")) {
                        return "A" + classValue
                    }
                    if (classValue.startsWith("K")) {
                        return "B" + classValue
                    }
                    return classValue
                };

                const numberA = getSortValue(valueA);
                const numberB = getSortValue(valueB);

                return numberA < numberB ? -1 : 1;
            },
        },
        filterFns: {
            containsString: (row, columnId, filterValue) => {
                // console.log({row, columnId})
                //return row.original[columnId].some((i) => i.name.includes(filterValue));

                const labelAttr = row
                    .getVisibleCells()
                    .find((i) => i.column.id === columnId).column.columnDef.labelAttr;
                
                if (row.original[columnId]) {
                    if (typeof row.original[columnId] === "object") {
                        if (row.original[columnId] instanceof Array) {
                            return row.original[columnId].some((i) => i[labelAttr].includes(filterValue))
                        }
                        // return row.original[columnId].some((i) => i[labelAttr].includes(filterValue))
                        return row.original[columnId][labelAttr]?.toLowerCase()
                            .includes(filterValue.toLowerCase())
                    } else if (typeof row.original[columnId] === "string") {
                        return row.original[columnId]
                            .toLowerCase()
                            .includes(filterValue.toLowerCase());
                    }
                } else {
                    return false
                }
            },
            isDateWithinRange: (row, columnId, value) => {
                const date = new Date(row.getValue(columnId));
                const [start, end] = value;
                // If one filter defined and date is null filter it
                if ((start || end) && !date) return false;
                if (start && !end) {
                    return date.getTime() >= start.getTime()
                } else if (!start && end) {
                    return date.getTime() <= end.getTime()
                } else if (start && end) {
                    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
                } else return true;
            },
            boolean: (row, columnId, filterValue) => {
                return row.original[columnId] == filterValue; // sic! == instead of ===
            },
        },
    })

    const validateCell = (cell) => {
        try {
            cell.getValue()
        } catch (e) {
            return true
        }
        return false
    }

    // if (!data) return;

    return (
        <div className="">
            <div className={noTopSpace ? "" : "group inline-flex gap-2 mt-1 mb-3"}>
                {/* Global search */}
                {!disableGlobalSearch && (
                    <div>
                        <div className="relative rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <DebouncedInput
                                name="table_search"
                                id="table_search"
                                value={globalFilter ?? ""}
                                onChange={(value) => setGlobalFilter(String(value))}
                                placeholder="Suche..."
                            />
                        </div>
                    </div>
                )}

                {/* Delete multiple */}
                <DeleteSelected rowSelection={rowSelection} onDelete={onDelete}/>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className={"min-w-full " + (hideHeader ? "" : "divide-y divide-gray-300")}>
                    <thead className={"bg-gray-50 " + (hideHeader ? " hidden" : "")}>
                    {table.getHeaderGroups().map((headerGroup, index) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                // select column
                                if (header.column.columnDef.columnType === "select") {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="py-3.5 text-center text-sm"
                                        >
                                            {header.column.columnDef.columnType === "select" && (
                                                <IndeterminateCheckbox
                                                    {...{
                                                        checked: table.getIsAllRowsSelected(),
                                                        indeterminate: table.getIsSomeRowsSelected(),
                                                        onChange: table.getToggleAllRowsSelectedHandler(),
                                                    }}
                                                />
                                            )}
                                        </th>
                                    );
                                }

                                // other columns
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        className={
                                            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 " +
                                            (index > 0 ? "sm:pl-6 " : "") +
                                            (header.column.columnDef.hide === true
                                                ? " hidden"
                                                : "") +
                                            " " +
                                            header.column.columnDef.headerClassNames
                                        }
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? "cursor-pointer select-none inline-flex"
                                                            : "px-1",
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: (
                                                            <span
                                                                className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                  <ChevronUpIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                  />
                                </span>
                                                        ),
                                                        desc: (
                                                            <span
                                                                className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                  <ChevronDownIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                  />
                                </span>
                                                        ),
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                    <div>
                                                        <Filter column={header.column} table={table} options={
                                                            filters
                                                                ? header.column.id === 'category_name'
                                                                    ? null
                                                                    : header.column.id === 'get_visibility_display'
                                                                        ? securityList
                                                                        : header.column.id === 'school_class' && filterMode === 'api'
                                                                            ? classes
                                                                            : header.column.id === 'house' && filterMode === 'api'
                                                                                ? houses
                                                                                : header.column.id === 'categories' && filterMode === 'api'
                                                                                    ? categories
                                                                                    : header.column.id === 'target_groups' && filterMode === 'api'
                                                                                        ? targetGroups
                                                                                : []
                                                                : null
                                                        }/>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row, index) => {
                        return (
                            <tr
                                key={row.id}
                                className={
                                    (getTrClassNames ? getTrClassNames(row, index) : (index % 2 === 0 ? undefined : "bg-gray-50 ")) +
                                    (getRowLink ? " cursor-pointer " : "")
                                }
                            >
                                {row.getVisibleCells().map((cell, columnIndex) => {

                                    if (validateCell(cell)) {
                                        return <td></td>
                                    }

                                    if (cell.column.columnDef.columnType === "select") {
                                        return (
                                            <td
                                                key={cell.id}
                                                className={`py-4 pl-1 text-sm text-center font-medium text-gray-900`}
                                            >
                                                <div className="px-1">
                                                    <IndeterminateCheckbox
                                                        {...{
                                                            checked: row.getIsSelected(),
                                                            indeterminate: row.getIsSomeSelected(),
                                                            onChange: row.getToggleSelectedHandler(),
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "plupu_rating") {
                                        const rating = cell.getValue();
                                        const ratingIcon = rating === 'Grün' ? '/img/plupu_gruen.png' : rating === 'Orange' ? '/img/plupu_gelb.png' : rating === 'Blau' ? '/img/plupu_blau.png' : null;
                                        return (
                                            <td
                                                key={cell.id}
                                                className={`py-1 pl-1 text-sm text-center font-medium text-gray-900`}
                                            >
                                                {ratingIcon && <img src={ratingIcon}
                                                     alt={rating}
                                                     width={20}
                                                     height={20}
                                                     className="inline-block"/>}
                                            </td>
                                        );
                                    }


                                    if (cell.column.columnDef.columnType === "student_no_image") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "py-4 pl-4 text-sm text-left font-medium text-gray-900 "
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                <TwoLineColumn
                                                    line1={`${cell.getValue().last_name}, ${
                                                        cell.getValue().first_name
                                                    }`}
                                                    line2={cell.getValue().student_number}
                                                />

                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "student") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "whitespace-nowrap py-4 pl-4 text-sm text-center font-medium text-gray-900 "
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                               <ColumnWithImage
                                                    userId={cell.getValue().user_id}
                                                    line1={`${cell.getValue().last_name}, ${
                                                        cell.getValue().first_name
                                                    }`}
                                                    line2={cell.getValue().student_number}
                                                />
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "alumni_student") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "whitespace-nowrap py-4 pl-4 text-sm text-center font-medium text-gray-900 "
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                <ColumnWithImageDirect
                                                    image={cell.getValue().image?.thumbnail}
                                                    line1={`${cell.getValue().last_name}, ${
                                                        cell.getValue().first_name
                                                    }`}
                                                    line2={cell.getValue().student_number}
                                                />
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "staff") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "whitespace-nowrap py-4 pl-4 text-sm text-center font-medium text-gray-900 "
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {cell.getValue() && (
                                                    <ColumnWithImage
                                                        userId={cell.getValue().user_id}
                                                        line1={`${cell.getValue().first_name} ${
                                                            cell.getValue().last_name
                                                        }`}
                                                        line2={cell.getValue().roles}
                                                    />
                                                )}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "date") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {cell.getValue() && formatDate(cell.getValue())}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "dateWithWeekday") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {cell.getValue() && formatDateWithWeekday(cell.getValue())}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "date_time") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {cell.getValue() && formatDateTime(cell.getValue())}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "m2m") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {parse(
                                                    typeof cell.getValue() === "object"
                                                        ? cell
                                                            .getValue()
                                                            ?.map((i) =>
                                                                cell.column.columnDef.labelAttr
                                                                    ? i[cell.column.columnDef.labelAttr]
                                                                    : ""
                                                            )
                                                            .join(
                                                                cell.column.columnDef.separator
                                                                    ? cell.column.columnDef.separator
                                                                    : ", "
                                                            )
                                                        : typeof cell.getValue() === "string"
                                                            ? cell.getValue()
                                                            : ""
                                                )}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "m2mLinked") {
                                        return (
                                            <td
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm " +
                                                    cell.column.columnDef.classNames
                                                }
                                            >
                                                {typeof cell.getValue() === "object"
                                                    ? cell.getValue()?.map((i, index) => {
                                                        return (
                                                            <>
                                                                <Link
                                                                    className="text-imsblue-600 hover:text-imsblue-900"
                                                                    to={cell.column.columnDef.getItemLink(i)}
                                                                >
                                                                    {cell.column.columnDef.labelAttr
                                                                        ? i[cell.column.columnDef.labelAttr]
                                                                        : ""}
                                                                </Link>
                                                                {index < cell.getValue().length - 1 ? cell.column.columnDef.separator
                                                                    ? parse(cell.column.columnDef.separator)
                                                                    : ", " : ''}
                                                            </>
                                                        );
                                                    })
                                                    : typeof cell.getValue() === "string"
                                                        ? cell.getValue()
                                                        : ""}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "yesno") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {cell.getValue()
                                                    ? cell.column.columnDef.yesLabel
                                                        ? cell.column.columnDef.yesLabel
                                                        : "Ja"
                                                    : cell.column.columnDef.noLabel
                                                        ? cell.column.columnDef.noLabel
                                                        : "Nein"}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "colouredStatus") {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide === true
                                                        ? " hidden"
                                                        : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                    + " " + cell.column.columnDef.statusClassNames(row)
                                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                                }
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "price") {

                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide ? " hidden" : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                }
                                            >
                                                {formatCurrency(cell.getValue())}
                                            </td>
                                        );
                                    }

                                    if (cell.column.columnDef.columnType === "number") {

                                        const displayValue = cell.getValue() ? cell.getValue() : 0

                                        return (
                                            <td
                                                onClick={() => {
                                                    if (
                                                        getRowLink &&
                                                        !cell.column.columnDef.disableRowLink
                                                    )
                                                        navigate(getRowLink(row));
                                                }}
                                                key={cell.id}
                                                className={
                                                    "break-word py-4 pl-4 pr-3 text-sm" +
                                                    (cell.column.columnDef.hide ? " hidden" : "") +
                                                    " " +
                                                    cell.column.columnDef.classNames
                                                }
                                            >
                                                {displayValue}
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            onClick={() => {
                                                if (
                                                    getRowLink &&
                                                    !cell.column.columnDef.disableRowLink
                                                )
                                                    navigate(getRowLink(row));
                                            }}
                                            key={cell.id}
                                            className={
                                                "break-word py-4 pl-4 pr-3 text-sm" +
                                                (cell.column.columnDef.hide === true
                                                    ? " hidden"
                                                    : "") +
                                                " " +
                                                cell.column.columnDef.classNames
                                                // + (columnIndex > 0 ? '' : ' pl-4')
                                            }
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                    {/* <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </tfoot> */}
                </table>
            </div>
        </div>
    );
}

function Filter({column, table, options = null}) {

    let firstValue
    table.getPreFilteredRowModel().flatRows.find(item => {
        if (item.original[column.id]) {
            firstValue = item.getValue(column.id)
            return item
        }
    })

    const columnFilterValue = column.getFilterValue();
    const columnType = column.columnDef.columnType;

    const getSortUniqueValue = () => {
        const uniqueValues = []
        table.getPreFilteredRowModel().flatRows.map(item => {
            try {
                let value = item.getValue(column.id)
                !uniqueValues.includes(value) && uniqueValues.push(value)
            } catch (e) {}
        })
        return uniqueValues.sort()
    }

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === "number"
                ? []
                : getSortUniqueValue(),
        [column, table.getPreFilteredRowModel().flatRows] // added second condition here - trying this out
    )

    const onChangeFunc = (value) => {
        if(options){
            if(value.length && !!options.length){
                const idValue = options.find(el => el.value === value)?.id
                if(idValue){
                    column.setFilterValue(idValue)
                }
            } else {
                column.setFilterValue(value)
            }
        } else {
            column.setFilterValue(value)
        }
    }

    if (columnType === "yesno") {

        const yesLabel = column.columnDef.yesLabel || 'Ja'
        const noLabel = column.columnDef.noLabel || 'Nein'

        return (
            <>
                <datalist id={column.id + "list"}>
                    <option value={yesLabel} />
                    <option value={noLabel} />
                </datalist>

                <div className="relative my-1 rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </div>
                    <DebouncedInput
                        type="text"
                        value={columnFilterValue === true ? yesLabel : (columnFilterValue === false ? noLabel : '')}
                        onChange={(value) => column.setFilterValue(value === yesLabel ? true : (value === noLabel ? false : ''))}
                        placeholder={`Filtern...`}
                        className="w-64 border shadow rounded"
                        list={column.id + "list"}
                    />
                </div>
            </>
        )
    }

    if (columnType === "date") {
        return (
            <div>
                <div className="flex flex-col gap-2">

                    <div className="flex gap-2 align-middle items-center justify-between">
                    <label htmlFor={column.id + "date_from"} className="block text-sm font-medium text-gray-900">
                        Von
                    </label>
                    <DatePicker
                        id={column.id + "date_from"}
                        name="date_from"
                        selected={columnFilterValue?.[0] || null}
                        dateFormat="dd.MM.yyyy"
                        maxDetail="day"
                        placeholderText="DD.MM.YYYY"
                        className="appearance-none block w-32 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-imsblue-500 focus:border-imsblue-500 sm:text-sm"
                        locale="de"
                        autoComplete="off"
                        value={columnFilterValue?.[0] ?? ""}
                        onChange={(value) =>
                            column.setFilterValue((old) => [value, old?.[1]])
                        }
                    /></div>
                    <div className="flex gap-2 align-middle items-center justify-between">
                    <label htmlFor={column.id + "date_to"} className="block text-sm font-medium text-gray-900">
                        Bis
                    </label>
                    <DatePicker
                        id={column.id + "date_to"}
                        name="date_to"
                        selected={columnFilterValue?.[1] || null}
                        dateFormat="dd.MM.yyyy"
                        maxDetail="day"
                        placeholderText="DD.MM.YYYY"
                        className="appearance-none block w-32 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-imsblue-500 focus:border-imsblue-500 sm:text-sm"
                        locale="de"
                        autoComplete="off"
                        value={columnFilterValue?.[1] ?? ""}
                        onChange={(value) =>
                            column.setFilterValue((old) => [old?.[0], value])
                        }
                    />
                    </div>
                </div>
            </div>
        )
    }

    switch (typeof firstValue) {
        case "number": {
            return (
                <div>
                    <div className="flex space-x-2">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <DebouncedInput
                            type="number"
                            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                            value={columnFilterValue?.[0] ?? ""}
                            onChange={(value) =>
                                column.setFilterValue((old) => [value, old?.[1]])
                            }
                            placeholder={`Min ${
                                column.getFacetedMinMaxValues()?.[0]
                                    ? `(${column.getFacetedMinMaxValues()?.[0]})`
                                    : ""
                            }`}
                            className="w-24 border shadow rounded"
                        />
                        <DebouncedInput
                            type="number"
                            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                            value={columnFilterValue?.[1] ?? ""}
                            onChange={(value) =>
                                column.setFilterValue((old) => [old?.[0], value])
                            }
                            placeholder={`Max ${
                                column.getFacetedMinMaxValues()?.[1]
                                    ? `(${column.getFacetedMinMaxValues()?.[1]})`
                                    : ""
                            }`}
                            className="w-24 border shadow rounded"
                        />
                    </div>
                </div>
            )
        }
        case "object": {
            return (
                <>
                    <datalist id={column.id + "list"}>
                        {[
                            ...new Set(
                                sortedUniqueValues.flat(1).map((i) => {
                                    const labelAttr = column.columnDef.labelAttr ? column.columnDef.labelAttr : 'name'
                                    if (!i) return ""; // || i.length <= 0
                                    return i[labelAttr] ? i[labelAttr] : "";
                                })
                            ),
                        ]
                            .slice(0, 5000)
                            .map((value) => (
                                <option value={value} key={value}/>
                            ))}
                    </datalist>

                    <div className="relative my-1 rounded-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </div>
                        <DebouncedInput
                            type="text"
                            value={columnFilterValue ?? ""}
                            onChange={(value) => column.setFilterValue(value)}
                            placeholder={`Filtern...`}
                            className="w-64 border shadow rounded"
                            list={column.id + "list"}
                        />
                    </div>
                </>
            )
        }
        case "boolean": {
            return (
                <>
                    <datalist id={column.id + "list"}>
                        <option value='Ja'/>
                        <option value='Nein'/>
                    </datalist>

                    <div className="relative my-1 rounded-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </div>
                        <DebouncedInput
                            type="text"
                            value={columnFilterValue === true ? 'Ja' : (columnFilterValue === false ? 'Nein' : '')}
                            onChange={(value) => column.setFilterValue(value === 'Ja' ? true : (value === 'Nein' ? false : ''))}
                            placeholder={`Filtern...`}
                            className="w-64 border shadow rounded"
                            list={column.id + "list"}
                        />
                    </div>
                </>
            )
        }
        default: {
            return (
                <>
                    <datalist id={column.id + "list"}>
                        {!options
                            ? sortedUniqueValues.slice(0, 5000).map((value) => (
                                <option value={value} key={value}/>
                            ))
                            : options.map((el) => (
                                <option value={el.value} key={el.id} id={el.id}/>
                            ))
                        }
                    </datalist>

                    <div className="relative my-1 rounded-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </div>
                        <DebouncedInput
                            type="text"
                            value={columnFilterValue ?? ""}
                            onChange={onChangeFunc}
                            placeholder={`Filtern...`}
                            className="w-64 border shadow rounded"
                            list={column.id + "list"}
                        />
                    </div>
                </>
            )
        }
    }
}

// A debounced input react component
function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input
            {...props}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="block rounded-md border-gray-300 pl-10 focus:border-imsblue-500 focus:ring-imsblue-500 sm:text-sm"
        />
    );
}

export function IndeterminateCheckbox({
                                          indeterminate,
                                          className = "",
                                          ...rest
                                      }) {
    const ref = React.useRef(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <input
            type="checkbox"
            ref={ref}
            className={
                className +
                " cursor-pointer rounded h-4 w-4 rounded border-gray-300 text-imsblue-600 focus:ring-imsblue-500"
            }
            {...rest}
        />
    );
}

// helper column types - link, user image, multiple lines

export function TableDownloadLink({apiFunction, fileName, label, ...rest}) {

    const {permissions} = useAuth(); // this is required for the download to work

    async function handleDownload(e) {
        const responseData = await apiFunction();
        fileDownload(responseData, fileName);
    }

    return (
        <a
            onClick={handleDownload}
            className="cursor-pointer text-imsblue-600 hover:text-imsblue-900"
            {...rest}
        >
            <DocumentArrowDownIcon
                className="inline align-middle h-5 w-5"
                aria-hidden="true"
            />{" "}
            {label}
        </a>
    );
}

export function TableLink({to, label, ...rest}) {
    return (
        <Link to={to} className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer" {...rest}>
            {label}
        </Link>
    );
}

export function TableEditLink({to, iconOnly, label, ...rest}) {
    return (
        <Link to={to} className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer" {...rest}>
            {!iconOnly && label}
            <PencilIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
        </Link>
    );
}

export function ColumnWithImage({
                                    userId,
                                    line1,
                                    line2,
                                    clearable,
                                    clearAction,
                                }) {


    const {renderUserImage} = useUserInfo();
    return (
        <div className="flex items-center justify-between space-x-2 px-2">
            <div className="flex items-center text-left overflow-hidden">
                <div className="h-10 w-10 flex-shrink-0">
                    {renderUserImage(userId)}
                </div>
                <div className="ml-4">
                    <div className="font-medium text-gray-900 truncate">{line1}</div>
                    <div className="text-gray-500 truncate">{line2}</div>
                </div>
            </div>
            {clearable && <div className="w-4">
                <XMarkIcon
                    height={20}
                    width={20}
                    className="mr-3 cursor-pointer"
                    onClick={clearAction}
                    color="red"
                />
            </div>}
        </div>
    );
}

export function ColumnWithImageDirect({
                                    image,
                                    line1,
                                    line2,
                                    clearable,
                                    clearAction,
                                }) {

    return (
        <div className="flex items-center justify-between space-x-2 px-2">
            <div className="flex items-center text-left overflow-hidden">
                <div className="h-10 w-10 flex-shrink-0">
                    <LazyLoadImage className="h-10 w-10 rounded-full" src={image} alt=""/>
                </div>
                <div className="ml-4">
                    <div className="font-medium text-gray-900 truncate">{line1}</div>
                    <div className="text-gray-500 truncate">{line2}</div>
                </div>
            </div>
            {clearable && <div className="w-4">
                <XMarkIcon
                    height={20}
                    width={20}
                    className="mr-3 cursor-pointer"
                    onClick={clearAction}
                    color="red"
                />
            </div>}
        </div>
    );
}

export function ColumnWithoutImage({
                                    line1,
                                    line2,
                                    clearable,
                                    clearAction,
                                }) {
    return (
        <div className="flex items-center justify-between space-x-2 px-2">
            <div className="flex items-center text-left overflow-hidden">
                <div className="ml-4">
                    <div className="font-medium text-gray-900 truncate">{line1}</div>
                    <div className="text-gray-500 truncate">{line2}</div>
                </div>
            </div>
            {clearable && <div className="w-4">
                <XMarkIcon
                    height={20}
                    width={20}
                    className="mr-3 cursor-pointer"
                    onClick={clearAction}
                    color="red"
                />
            </div>}
        </div>
    );
}

export function TwoLineColumn({line1, line2}) {
    return (
        <>
            <div className="text-gray-900">{line1}</div>
            <div className="text-gray-500">{line2}</div>
        </>
    );
}

export function TwoLineColumnWithLink({line1, line2, link}) {
    return (
        <>
            <a href={link} target="_blank">
                <div className="text-gray-900">{line1}</div>
                <div className="text-gray-500">{line2}</div>
            </a>
        </>
    );
}

export function DeleteSelected({flatRows, rowSelection, onDelete}) {
    if (!rowSelection || !onDelete) return;
    const selectedRowsCount = Object.keys(rowSelection).length;
    if (selectedRowsCount <= 0) return <></>;
    return (
        <>
            <div className="flex items-center text-left">
                <button
                    type="button"
                    onClick={onDelete}
                    className="inline-flex items-center justify-center px-4 py-2
                           border border-gray-300 shadow-sm text-sm font-medium rounded-md
                           text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
                           focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                           focus:ring-blue-500"
                >
                    <TrashIcon className="h-5 w-5 pr-1 text-red-600"/>
                    {selectedRowsCount} ausgewählte löschen
                </button>
            </div>
        </>
    );
}
